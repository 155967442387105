import app from '../../config/configApp';
import {
    Alert,
    AutoComplete,
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Calendar,
    Card,
    Carousel,
    Cascader,
    Checkbox,
    Col,
    Collapse,
    Comment,
    DatePicker,
    Divider,
    Drawer,
    Dropdown,
    Empty,
    Form,
    Icon,
    Input,
    InputNumber,
    Layout,
    List,
    Menu,
    message,
    Modal,
    PageHeader,
    Pagination,
    Popconfirm,
    Popover,
    Progress,
    Radio,
    Rate,
    Result,
    Row,
    Select,
    Skeleton,
    Slider,
    Space,
    Spin,
    Statistic,
    Steps,
    Switch,
    Table,
    Tabs,
    Tag,
    Timeline,
    TimePicker,
    Tooltip,
    Tree,
    TreeSelect,
    Upload,
} from 'ant-design-vue';

// app.prototype.$message = message;
app.config.globalProperties.$message = message

app.use(Alert)
app.use(Rate)
app.use(Button)
app.use(Menu)
app.use(Layout)
app.use(Switch)
app.use(Progress)
app.use(Icon)
app.use(Row)
app.use(Col)
app.use(Input)
app.use(InputNumber)
app.use(AutoComplete)
app.use(Popover)
app.use(Checkbox)
app.use(Divider)
app.use(Avatar)
app.use(Dropdown)
app.use(Drawer)
app.use(Badge)
app.use(Breadcrumb)
app.use(Empty)
app.use(Skeleton)
app.use(Spin)
app.use(Statistic)
app.use(Slider)
app.use(DatePicker)
app.use(TimePicker)
app.use(Timeline)
app.use(PageHeader)
app.use(Calendar)
app.use(Card)
app.use(Carousel)
app.use(Radio)
app.use(Cascader)
app.use(Collapse)
app.use(Comment)
app.use(Tooltip)
app.use(List)
app.use(Form)
app.use(Space)
app.use(Upload)
app.use(Select)
app.use(Table)
app.use(Modal)
app.use(Pagination)
app.use(Popconfirm)
app.use(Result)
app.use(Tag)
app.use(Steps)
app.use(Tabs)
app.use(Tree)
app.use(TreeSelect)
