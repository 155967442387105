<template>
  <div v-if='isLoading' class='spin'>
    <a-spin />
  </div>
  <ThemeProvider
    v-else
    :theme='{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }'
  >
    <Suspense>
      <template #default>
        <router-view></router-view>
      </template>
      <template #fallback>
        <div class='spin'>
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './config/theme/themeVariables';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    ThemeProvider,
  },
  setup() {
    const { state } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
    };
  },
  methods: {
    updateFavicon() {
      const hostname = window.location.hostname;
      let faviconPath = `${process.env.BASE_URL}favicon.png`; // Varsayılan favicon yolu
      if (hostname.includes('cargo.tekrom.com')) {
        faviconPath = `${process.env.BASE_URL}favicontsoft.png`;
      } else {
        faviconPath = `${process.env.BASE_URL}favicon.png`;
      }
      const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = faviconPath;
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    updateTitle() {
      const hostname = window.location.hostname;
      let title;
      if (hostname.includes('cargo.tekrom.com')) {
        title = 'T-Soft Kargo';
      } else {
        title = 'Cargong';
      }
      document.title = title;
    },
  },
  mounted() {
    this.updateFavicon();
    this.updateTitle();
  },
};
</script>
