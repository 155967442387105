export default {
    setToken(state,token) {
      state.jwtToken = token;
    },
    setLogin(state,status){
        state.login = status;
    },
    // setRole(state,role){
    //     state.role=role;
    // }
  };
  