import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    // T-Soft Apps ve diğerleri kullanıyor olabilir, incelenecek
    path: '/auth',
    component: () => import('@/layout/withAuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/view/authentication/SignIn.vue'),
      },
    ],
  },
  {
    path: '/guest',
    component: () => import('@/layout/withAuthLayout.vue'),
    children: [
      {
        path: 'tsoft-kargo/login-as/:token',
        name: 'T.LoginAs.Page',
        component: () => import('@/view/tsoft-kargo/LoginAs.vue'),
      },
      {
        path: 'cargong/login-as/:token',
        name: 'C.LoginAs.Page',
        component: () => import('@/view/cargong/LoginAs.vue'),
      },
    ],
  },
  {
    path: '/member',
    component: () => import('@/layout/withAdminLayout.vue'),
    children: [
      {
        path: 'settings',
        name: 'O.Settings.Page',
        component: () => import('@/view/pages/Settings.vue'),
      },
      {
        path: 'cargong/payment-history',
        name: 'C.PaymentHistory.Page',
        component: () => import('@/view/pages/PaymentHistory.vue'),
      },
      {
        path: 'order',
        name: 'O.Order.Page',
        component: () => import('@/view/pages/Order.vue'),
      },
      {
        path: 'help-desk',
        name: 'O.HelpDesk.Page',
        component: () => import('@/view/pages/HelpDesk.vue'),
      },

      {
        path: 'tsoft-kargo/index',
        name: 'T.Index.Page',
        component: () => import('@/view/pages/ContractedPanel.vue'),
      },
      {
        path: 'tsoft-kargo/contracted-cargo',
        name: 'T.CargoPrices.Page',
        component: () => import('@/view/pages/ContractedCargo.vue'),
      },
      {
        path: 'tsoft-kargo/send-manual-cargo',
        name: 'T.SendManualCargo.Page',
        component: () => import('@/view/pages/SendManualCargo.vue'),
      },
      {
        path: 'tsoft-kargo/shipments',
        name: 'T.ShipmentList.Page',
        component: () => import('@/view/tsoft-kargo/ShipmentList.vue'),
      },
      {
        path: 'tsoft-kargo/receipt',
        name: 'T.Receipt.Page',
        component: () => import('@/view/pages/Receipt.vue'),
      },
      {
        path: 'tsoft-kargo/payment',
        name: 'T.PaymentContract.Page',
        component: () => import('@/view/pages/PaymentContract.vue'),
      },

      {
        path: 'cargong/index',
        name: 'C.Index.Page',
        component: () => import('@/view/pages/MainPage.vue'),
      },
      {
        path: 'cargong/company',
        name: 'C.ShipmentCompaniesSettings.Page',
        component: () => import('@/view/pages/cargong/ConfiguredShippingCompanies.vue'),
      },
      {
        path: 'cargong/company-map',
        name: 'C.ShippingCompanyMap.Page',
        component: () => import('@/view/pages/InsertMap.vue'),
      },
      {
        path: 'cargong/shipments',
        name: 'C.ShipmentList.Page',
        component: () => import('@/view/cargong/ShipmentList.vue'),
      },
      {
        path: 'cargong/payment',
        name: 'C.Payment.Page',
        component: () => import('@/view/pages/Payment.vue'),
      },
      {
        path: 'cargong/wallet',
        name: 'C.Wallet.Page',
        component: () => import('@/view/pages/Wallet.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  linkExactActiveClass: 'active',
  routes,
})

export default router
