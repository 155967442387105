import app from './config/configApp'
import restMethods from '@/utility/restMethods'
import router from './routes/router'
import store from '@/vuex/store'
import './static/css/style.css'
import { t } from '@/utility/language'

import '@/core/plugins/ant-design'
import '@/core/plugins/fonts'
import '@/core/plugins/maps'
import '@/core/plugins/masonry'
import '@/core/plugins/apexcharts'
import '@/core/components/custom'
import '@/core/components/style'

app.config.productionTip = false
app.config.globalProperties.$restMethods = restMethods
app.config.globalProperties.$t = t
app.config.globalProperties.__ = function(key, defaultValue, params){
    let xValue = t[key] || defaultValue;
    //TODO proc params
    return xValue;
}
app.provide('$t', t)
app.use(store)
app.use(router)
app.mount('#app')
