import EN from '../locales/en.json'
import TR from '../locales/tr.json'

export let t = ''

switch (localStorage.getItem('lang')) {
    case 'EN':
        t = EN
        break;
    case 'TR':
        t = TR
        break;
    default:
        t = TR
        break;
}

export const avaibleLangs = ['TR', 'EN']