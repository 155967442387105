import mutations from './mutations';
// import Cookies from 'js-cookie';

const state = () => ({
  // role:localStorage.getItem('role'),
  // login: localStorage.getItem('logedIn'),
  jwtToken: localStorage.getItem('jwtToken'),
});


const actions = {
  async login({ commit }, authData) {
    console.log('authData', authData);
    commit('setToken', authData.token);
    localStorage.setItem('jwtToken', authData.token);
    if (authData.type == 1 || authData.type == 0) {
      localStorage.setItem('jwtContract', authData.type);
    }
  },
  async logOut({ commit }) {
    commit('setToken', '');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtContract');
    commit('setLogin', false);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
